import React from 'react';
import styled from 'styled-components';
import { Tags, Tag } from 'components/Tags';
import Card from 'components/Card';
import Img from 'gatsby-image';
import { Box, Stack } from '@tymate/margaret';
import { Link } from 'gatsby';

const Thumbnail = styled(Img)``;

const ArticleCardBase = styled(Card).attrs({ as: Link })`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ArticleCardContent = styled(Box).attrs({
  padding: 1,
  size: 'full',
})`
  flex: 1;
`;

const ArticleCard = ({ article }) => {
  const { title, tags, kicker, cover, slug, meta } = article;

  return (
    <ArticleCardBase to={`/blog/${slug}`}>
      <Thumbnail fluid={{ ...cover.fluid, aspectRatio: 2 }} />

      <ArticleCardContent>
        <Stack size="full" alignX="space-between" alignY="center">
          <Tags>
            {tags.map(({ name }, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          </Tags>

          <Card.Timestamp timestamp={meta?.firstPublishedAt} />
        </Stack>

        <Card.Title marginVertical={0.5}>{title}</Card.Title>

        <div style={{ flex: 1 }}>{kicker}</div>
      </ArticleCardContent>

      <Card.MoreLink to={`/blog/${slug}`}>Lire l’article</Card.MoreLink>
    </ArticleCardBase>
  );
};

export default ArticleCard;
