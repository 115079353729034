import React from 'react';
import styled, { css } from 'styled-components';
import { List } from '@tymate/margaret';
import { Link } from 'gatsby';
import { fontStyles } from 'ui';

export const Tags = styled(List)`
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.spacing(-0.5)};
  margin-top: ${({ theme, marginTop }) =>
    theme.spacing((marginTop || 0) - 0.25)};

  > * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    margin-top: ${({ theme }) => theme.spacing(0.25)};
  }
`;

const TagItem = styled.li``;

const TagLink = styled.span`
  ${fontStyles.bodySmall}
  color: ${({ theme }) => theme.primary};
  text-decoration: none;

  :before {
    content: '#';
  }

  ${({ variant, theme, isActive }) =>
    variant === 'pill' &&
    css`
      outline: none;
      display: block;
      transition: background 150ms ease, color 150ms ease;
      padding: ${theme.spacing(0.125)} ${theme.spacing(0.5)};
      border: 1px solid ${theme.primary};
      border-radius: 999em;
      background-color: ${isActive ? theme.primary : 'transparent'};
      color: ${isActive ? '#ffffff' : theme.primary};

      &:hover {
        background-color: ${theme.primary};
        color: #ffffff;
      }

      &:focus {
        box-shadow: ${theme.primaryOutlineShadow};
      }
    `}
`;

export const Tag = ({ to, children, ...props }) => (
  <TagItem>
    <TagLink to={to} as={Boolean(to) ? Link : null} {...props}>
      {children}
    </TagLink>
  </TagItem>
);
